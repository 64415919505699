<!--
 * @Description: 导航
 * @Autor: JINbiao
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-02 20:50:12
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '菜单栏',
          list: [
            {
              name: '顶部菜单',
              path: '/mall/advert/header'
            }
          ]
        },
        {
          label: '轮播图',
          list: [
            {
              name: '轮播图列表',
              path: '/mall/advert/advert-add'
            }
          ]
        }
        // {
        //   label: '自定义导航',
        //   list: [
        //     {
        //       name: '导航列表',
        //       path: '/mall/advert/navigator'
        //     }
        //   ]
        // }
      ]
    }
  }
}
</script>
